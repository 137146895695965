import React from "react";
import ReactWaves from "@dschoon/react-waves";

import africa from "./audio/Surah-Fatihah-Quran.com_.mp3";

export default class BasicExample extends React.Component {
  state = {
    playing: false,
  };

  render() {
    return (
      <div className={"container example"}>
        {/* Play/Pause button */}
        <span
          className="play button"
          onClick={() => {
            this.setState({ playing: !this.state.playing });
          }}
          style={{ cursor: 'pointer'}}
        >
          {!this.state.playing ? "▶" : "■"}
        </span>

        {/* ReactWaves component */}
        <ReactWaves
          audioFile={africa}
          className={"react-waves w-100"}
          options={{
            barHeight: 2,
            cursorWidth: 0,
            height: 200,
            hideScrollbar: true,
            progressColor: "rgb(16, 99, 7)",
            responsive: true,
            waveColor: "#ffffc3",
          }}
          volume={1}
          zoom={1}
          playing={this.state.playing}

        />
      </div>
    );
  }
}
