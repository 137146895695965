import './App.css';
import BasicExample from './BasicExample';





function App() {
  return (
    <div className="App">
      <header className="App-header">


        <h1>بِسْمِ اللهِ الرَّحْمٰنِ الرَّحِيْم</h1><br />
        <h3>We Will Launch Soon</h3>
        <p>
          While you're here, listen to the recitation of Qur'an by Mishari Rashid al-Afasy
          Surah Al-Fatihah
        </p>

  <BasicExample />

      </header>

    </div>
  );
}

export default App;
